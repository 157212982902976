import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Calender');
    this.meta.updateTag({ name: 'description', content: 'Luxury Companion Sasha Noelle' });
    this.meta.updateTag({ name: 'keywords', content: 'companion, model, houston, texas, dc, luxury' });
  }

}
