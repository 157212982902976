<div id="footer">
  <ul class="FotLinks">
    <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tantalize</a></li>
    <li><a routerLink="/discover" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Discover</a></li>
    <li><a routerLink="/visuals" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">visuals</a></li>
    <li><a routerLink="/sessions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">sessions</a></li>
    <li><a routerLink="/decorum" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">decorum</a></li>
    <li><a routerLink="/tours" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tours</a></li>
    <li><a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">contact</a></li>

  </ul>
  <div id="copyright">
      2014 - {{year}} <a >Sashanoelle.nl</a> All Rights Reserved.
   </div>

</div>
<ul id="SocialMedia">
    <li><a href="https://twitter.com/TheSashaNoelle" target="_blank"><img src="/assets/images/social_twitter.svg" width="20" alt="Twitter" /></a></li>
    <li><a href="https://www.instagram.com/TheSashaNoelle/" target="_blank"><img src="/assets/images/social_instagram.svg" height="20" alt="Instagram" /></a></li>
  </ul>
