import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tantalize',
  templateUrl: './tantalize.component.html',
  styleUrls: ['./tantalize.component.scss']
})
export class TantalizeComponent implements OnInit {
  page_html = '';
  page_img = '';
  constructor(private title: Title, private meta: Meta, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.title.setTitle('Tantalize | Sasha Noelle | Heavenly Ἑταίρα');
    this.meta.updateTag({ name: 'description', content: 'Luxury Companion Sasha Noelle' });
    this.meta.updateTag({ name: 'keywords', content: 'companion, model, houston, texas, dc, luxury' });
    this.page_html = localStorage.getItem('tantalizeHtml');
    this.page_img = localStorage.getItem('tantalizeImage');
    let Interval = setInterval(() => {
      if (!this.page_html) {
        this.page_html = localStorage.getItem('tantalizeHtml');
      }
      if (!this.page_img) {
        this.page_img = localStorage.getItem('tantalizeImage');
      }
      if (this.page_html && this.page_img) {
        clearInterval(Interval);
      }
    })
  }

}
