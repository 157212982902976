import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TantalizeComponent } from './pages/tantalize/tantalize.component';

const routes: Routes = [
  { path: '',  component: TantalizeComponent },
  //{ path: '**', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'visuals', loadChildren: () => import('./pages/visuals/visuals.module').then(m => m.VisualsModule) },
  { path: 'visuals/albums', loadChildren: () => import('./pages/albums/albums.module').then(m => m.AlbumsModule) },
  { path: 'sessions', loadChildren: () => import('./pages/sessions/sessions.module').then(m => m.SessionsModule) },
  { path: 'decorum', loadChildren: () => import('./pages/decorum/decorum.module').then(m => m.DecorumModule) },
  { path: 'tours', loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarModule) },
  { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
  { path: 'tantalize', loadChildren: () => import('./pages/tantalize/tantalize.module').then(m => m.TantalizeModule) },
  { path: 'discover', loadChildren: () => import('./pages/discover/discover.module').then(m => m.DiscoverModule) },
  { path: 'verification', loadChildren: () => import('./pages/verification/verification.module').then(m => m.VerificationModule) },
  { path: 'booking', loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingModule) },
  { path: 'sucessfuly-submitted/:type', loadChildren: () => import('./pages/sucessfuly-submitted/sucessfuly-submitted.module').then(m => m.SucessfulySubmittedModule) },
  { path: 'sucessfuly-submitted/', loadChildren: () => import('./pages/sucessfuly-submitted/sucessfuly-submitted.module').then(m => m.SucessfulySubmittedModule) },
  { path: 'verification_test', loadChildren: () => import('./pages/verification-test/verification-test.module').then(m => m.VerificationTestModule) },
  { path: 'calendar_test', loadChildren: () => import('./pages/calendar-test/calendar-test.module').then(m => m.CalendarTestModule) },
  { path: 'email-sign-up',  redirectTo: '/mailing_list', pathMatch: 'full' },
  { path: 'mailing_list', loadChildren: () => import('./pages/email-sign-up/email-sign-up.module').then(m => m.EmailSignUpModule) },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
