import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  //popupdisplayed = localStorage.getItem('popupdisplayed')?localStorage.getItem('popupdisplayed'):'true';
  constructor(private httpClient: HttpClient) { }
  popupdisplayed = true;
  title = 'Sasha Noelle Luxury Companion Visuals';
  ngOnInit(): void {
   // throw new Error('Method not implemented.');
   // alert(this.popupdisplayed);
    this.httpClient.get(environment.ApiUrl + "pages/get?name=tantalize", { responseType: 'text' }).subscribe(data => {
      localStorage.setItem('tantalizeHtml', data);
    });
    this.httpClient.get(environment.ApiUrl + "pages/get_image?name=tantalize").subscribe(data => {
      localStorage.setItem('tantalizeImage', data['image']);
      document.body.insertAdjacentHTML('beforeend', '<img src="' + data['image'] + '" style="display:none;" />')
    });
    this.httpClient.get(environment.ApiUrl + "pages/get?name=discover", { responseType: 'text' }).subscribe(data => {
      localStorage.setItem('discoverHtml', data);
    });
    this.httpClient.get(environment.ApiUrl + "pages/get_image?name=discover").subscribe(data => {
      localStorage.setItem('discoverImage', data['image']);
      document.body.insertAdjacentHTML('beforeend', '<img src="' + data['image'] + '" style="display:none;" />')
    });
    this.httpClient.get(environment.ApiUrl + "pages/get?name=sessions", { responseType: 'text' }).subscribe(data => {
      localStorage.setItem('sessionsHtml', data);
    });
    this.httpClient.get(environment.ApiUrl + "pages/get_image?name=sessions").subscribe(data => {
      localStorage.setItem('sessionsImage', data['image']);
      document.body.insertAdjacentHTML('beforeend', '<img src="' + data['image'] + '" style="display:none;" />')
    });
    this.httpClient.get(environment.ApiUrl + "pages/get?name=decorum", { responseType: 'text' }).subscribe(data => {
      localStorage.setItem('decorumHtml', data);
    });
    this.httpClient.get(environment.ApiUrl + "pages/get_image?name=decorum").subscribe(data => {
      localStorage.setItem('decorumImage', data['image']);
      document.body.insertAdjacentHTML('beforeend', '<img src="' + data['image'] + '" style="display:none;" />')
    });
    this.httpClient.get(environment.ApiUrl + "pages/get?name=tours", { responseType: 'text' }).subscribe(data => {
      localStorage.setItem('toursHtml', data);
    });
    this.httpClient.get(environment.ApiUrl + "pages/get_image?name=tours").subscribe(data => {
      localStorage.setItem('toursImage', data['image']);
      document.body.insertAdjacentHTML('beforeend', '<img src="' + data['image'] + '" style="display:none;" />')
    });
    this.httpClient.get(environment.ApiUrl + "pages/get?name=contact", { responseType: 'text' }).subscribe(data => {
      localStorage.setItem('contactHtml', data);
    });
    this.httpClient.get(environment.ApiUrl + "pages/get_image?name=contact").subscribe(data => {
      localStorage.setItem('contactImage', data['image']);
      document.body.insertAdjacentHTML('beforeend', '<img src="' + data['image'] + '" style="display:none;" />')
    });
  }
  
  popupAccept(){
    localStorage.setItem('popupdisplayed', 'true');
    this.popupdisplayed = false;
  }

  getAccept1(): boolean {
    return localStorage.getItem('popupdisplayed') == 'true' ? true : false;
  }

  get fullName() {
    return localStorage.getItem('popupdisplayed') == 'true' ? true : false;
  }
}

//localStorage.setItem('popupdisplayed', 'true');

// General popup for storing
//localStorage.setItem('popupdisplayed', 'true');
//if(localStorage.getItem('popupdisplayed') === 'true') {
  //alert('Hi')
//}

// To remove a value/item from localStorage
//localStorage.removeItem("some-key");
