

<div id="wrapper" >
  <div id="shell">
    <app-main-header></app-main-header>
    <div id="middle" [class.hidden]=0 >
      <router-outlet></router-outlet>
    </div>
    <app-main-footer></app-main-footer>
  </div>
</div>

<div *ngIf="!fullName">
  <div class="modal_overlay">
    <div class="modal">
      <h2>Disclaimer</h2>
      <p margin-top="5">This site contains images that may be considered adult in nature. By clicking accept you agree that this material is legal to view within your jurisdiction, and that you are 18 years of age or older. This site site does not promote or provide any illegal services including prostitution. Any payment is for time and companionship only, and not sexual services. </p>
      <button class="btnModal"  (click)="popupAccept()">Accept</button>
      <a class="btnModal"  href="https://duckduckgo.com/"> Leave </a>
    </div>
    <div class="modalLogo"><img  alt="Persona non grata"  src="/assets/images/modal-disclaimer-popup-logo.webp"></div>
    <!-- <div class="socialLinks">
      <a href="https://www.preferred411.com/index.cfm?fx=Client&amp;Refer=96001" target="_blank">
        <img  alt="Preferred411.com" height="75" src="https://www.preferred411.com/a/preferredSeal-g.png">
      </a>
      <a href="https://www.theeroticreview.com">
        <img src="https://www.theeroticreview.com/images/reviewed_seal.gif" border="0" alt="banner" height="75">
      </a>
    </div> -->
  </div>
</div>

