<div id="menu">
  <div id="logo">
    <p class="text-center m-0"> <a ><img src="/assets/images/heavenly1-01.webp" height="140" alt="Sasha Noelle Heavenly Ἑταίρα" /></a> </p>
      <div id="abovebar1">
        <ul>
          <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tantalize</a></li>
          <li><a routerLink="/discover" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Discover</a></li>
          <li><a routerLink="/visuals" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">visuals</a></li>
          <li><a routerLink="/sessions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">sessions</a></li>
          <li><a routerLink="/decorum" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">decorum</a></li>
          <li><a routerLink="/tours" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tours</a></li>
          <li><a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">contact</a></li>
        </ul>
        <div id="belowbar2">
        </div>
      </div>
  </div>
</div>
