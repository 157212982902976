<div id="MainBanner">
  <a routerLink="/">
     <picture>
       <img src="{{ page_img }}" alt="Sasha Noelle Luxury Model" style="width:100%;">
     </picture>
   </a>
</div>

<div class="content fs_14 lh-2 tantalize">
  <div class="content-sm fs_14  mt-3" [innerHTML]="page_html | safeHtml">
  </div>
</div>
